import { FC } from 'react';

import PopUpItemButton from 'components/PopUpItemButton';
import Text from 'components/ui/Text';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ICurrency } from 'types/currency';

import 'swiper/css/navigation';

import styles from './CardPopup.module.scss';

interface ICardPopupProps {
  onClick: () => void;
  imageUrl?: string[];
  name?: string;
  description?: string;
  currency?: ICurrency;
  price?: string | number;
}

const CardPopup: FC<ICardPopupProps> = ({
  imageUrl,
  name,
  description,
  currency,
  price,
  onClick,
}) => (
  <div className={styles.card_popup}>
    <div className={styles.card_popup__content}>
      {imageUrl?.length ? (
        <Swiper navigation={true} modules={[Navigation]}>
          {imageUrl?.map((img, idx) => (
            <SwiperSlide key={img + idx}>
              <div className={styles.card_popup__image}>
                <img
                  src={img || '/images/placeholder_image.png'}
                  alt={name}
                  loading="lazy"
                />
                <div className="swiper-lazy-preloader" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className={styles.card_popup__image}>
          <img src="/images/placeholder_image.png" alt="No img" />
        </div>
      )}
      <div className={styles.card_popup__text}>
        <Text variant="h1" className={styles.card_popup__text__title}>
          {name}
        </Text>
        <Text variant="body-1" className={styles.card_popup__text__description}>
          {description}
        </Text>
      </div>
    </div>
    <PopUpItemButton
      onClick={onClick}
      currency={currency?.name}
      price={price}
    />
  </div>
);

export default CardPopup;
